module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-helmet-canonical-urls@1.4.0_gatsby-plugin-react-helmet@6.14.0_gatsby@5.13_2nuckbwqtmc3dr2hxkmmyfxmne/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.roche.by","exclude":["/media/releases/[slug]","/investors/updates/[slug]","/medien-schweiz/informationen/[slug]","/en/media-switzerland/informationen/[slug]","/fr/presse-suisse/informationen/[slug]"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_@babel+plugin-syntax-flow@7.25.9_@babel+core@7.25_ltle4yyzolsih7npjkdojtunki/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/resources/images/favIcon.png","icons":[{"src":"src/resources/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/resources/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/resources/images/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/resources/images/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/resources/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/resources/images/favicon.ico","sizes":"48x48 32x32 16x16","type":"image/x-icon"},{"src":"src/resources/images/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"src/resources/images/safari-pinned-tab.svg","sizes":"512x512","type":"image/svg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7f5356ff0eaba89671bed4a1828ef379"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_@babel+plugin-syntax-flow@7.25.9_@babel+core@7.25.9__@babel+plugin-transform-re_y7jfw2hj3fee6seag2tseaycfi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
